*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@media (min-width: 769px) {
  :root {
    font-size: 15px;
  }
}

html,
body {
  box-sizing: inherit;
  min-height: 100%;
  line-height: 1.5;
}

body {
  background: #f2f2f2;
  color: #393d3f;
  margin: 0;
}

a {
  text-decoration: none;
  color: initial;
}

.contain {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .contain {
    flex-wrap: nowrap;
  }
}
@media (min-width: 1615px) {
  .contain {
    border-radius: 6px;
  }
}
.Main {
  width: 100%;
}
@media (min-width: 768px) {
  .Main {
    padding-left: 18rem;
  }
}
.fill {
  width: 100%;
  min-height: calc(100vh - 75px);
  background: #e9eef2;
}

.typcn {
  padding-left: 0;
  padding-right: 0.422rem;
  text-indent: 0;
}

.title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 6rem;
  padding-left: 1.777rem;
  background: white;
  border-bottom: 1px solid rgba(44, 63, 80, 0.2);
  font-family: 'Lora', serif;
  font-size: 1.777rem;
  margin: 0;
  width: 100%;
}

.u-link {
  color: #068ab1;
}

@media (min-width: 768px) {
  .u-link:hover {
    text-decoration: underline;
  }
}
